import { ApiBase } from './ApiBase'

import { NextInspectionDto, ISchedule } from './types/checklist'

/**
 * Loads schedules from the backend
 */
export default class ScheduleApi extends ApiBase {
  public async index(checklistId: number) {
    const response = await this.get<ISchedule[]>(`/checklists/${checklistId}/schedules`)
    return response.data
  }

  /**
   * List the inspections for the next week
   */
  public nextInspections() {
    return this.get<NextInspectionDto[]>('/schedules/next_inspections')
  }

  /**
   * List the inspections for the next week by ressource
   */
  public byResource(id: number) {
    return this.get<NextInspectionDto[]>(`/schedules/by_ressource?ressource_id=${id}`)
  }
}
