import { FunctionComponent } from 'react';
import OneSignal, { useOneSignalSetup } from 'react-onesignal';
import { useSelector } from 'react-redux';
import { IAlphaprocessState } from './store';
const OneSignalSetup: FunctionComponent = () => {
  const user = useSelector((state: IAlphaprocessState) => state.core.user);
  useOneSignalSetup(async () => {
    await OneSignal.setEmail(user.email);
    await OneSignal.setExternalUserId(user.id.toString());
    await OneSignal.registerForPushNotifications();
  });
  return null;
};
export default OneSignalSetup;