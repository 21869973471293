import { ITask } from '../tasksApi'
import IAttachment, { ICloudAttachment } from './attachment'
import { IQuestion } from './checklist'
import IContact from './contact'
import IUser from './user'

export interface ISignature {
  id?: number | string
  user_id: number | null
  signature: IAttachment
  display_user_name: string
  is_tester: boolean
  contact_id?: number
  contact?: IContact
  user?: IUser
  _dirty?: boolean
  user_name?: string
}

export interface IComment {
  id?: number | string
  text: string
  user?: IUser
  commentable?: IResult
  user_id?: number
  commentable_id: string
  _dirty?: boolean
  _destroy?: boolean
  commentable_type: string
  created_at?: Date
}

export interface IResultAttachementObject extends IAttachment {
  thumb?: IAttachment
  report?: IAttachment
}

export interface IResultAttachment {
  _destroy?: boolean
  id?: number
  attachment: IResultAttachementObject
  access_token?: string | null
  filename?: string
  mimetype?: string
}

type ActionType = 'set_question_value' | 'show_question' | 'hide_question' | 'show_area' | 'duplicate_area' | 'duplicate_question' | 'hide_area' | 'count_as_error' | 'create_task' | 'send_email';
export interface ResultAction {
  action: ActionType
  target_question_ids?: number[]
  target_area_ids?: number[]
  target_area_count?: number
  value?: string
  task_data?: any
  target_question_count?: number,
  area_position?: number
  message?: string
  area_info?: { area_id: string | number, area_position: number }
  origin_question?: IQuestion
}

export default interface IResult {
  _new?: boolean
  id?: string
  created_at?: Date
  updated_at?: Date
  question_id: number
  question?: IQuestion
  value: string | null
  option_text?: string | null
  result_set_id: number
  audit_points: number
  attachments?: IResultAttachment[]
  cloud_attachments?: ICloudAttachment[]
  versions: any[] // TODO: create type for version
  contact_id?: number
  signee?: string
  user_id?: number
  signature?: IAttachment
  action?: ResultAction[]
  comments?: IComment[]
  area_position: number
  tasks?: ITask[]
  _dirty?: boolean
  signee_name?: string
  _working?: boolean
  question_position: number
  showCount?: number
}

export const numberValueFormat = /^[0-9_.,-]+$/
