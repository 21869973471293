import { Tooltip } from '@mui/material';
import { Tag } from 'bloomer';
import clsx from 'clsx';
import { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import { setNextInspectionsSort } from '../../../actions/sortActions';
import api from '../../../api';
import { NextInspectionDto } from '../../../api/types/checklist';
import { FilterSettings } from '../../../reducers/filterReducer';
import { ISortSetting } from '../../../reducers/sortReducer';
import { IAlphaprocessState } from '../../../store';
import ITranslationProps from '../../../types/translationProps';
import LoadSpinner from '../../loadSpinner/LoadSpinner';
import DataTable from '../../widgets/DataTable';
import { IDataTableHeader } from '../../widgets/DataTableHeader';
interface IState {
  /**
   * Raw data from the backend
   */
  scheduledChecklists: NextInspectionDto[];
  /**
   * Sorted and filtered data
   */
  processedData: NextInspectionDto[];
  isLoading: boolean;
  error: any;
  currentPage: number;
}
interface IStateProps {
  sortSettings: ISortSetting;
  isAdmin: boolean;
  filters: FilterSettings;
}
interface IDispatchProps {
  setSort: (setting: ISortSetting) => void;
}
type Props = ITranslationProps & IStateProps & IDispatchProps;

/**
 * Items per page
 */
const PER_PAGE = 15;

/**
 * Table containing all scheduled checklists
 */
class ScheduledChecklistsTable extends Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      scheduledChecklists: [],
      processedData: [],
      isLoading: true,
      error: null,
      currentPage: 1
    };
  }
  public async componentDidMount() {
    try {
      const {
        data
      } = await api.schedules.nextInspections();
      this.setState({
        scheduledChecklists: data
      }, () => this.filterAndSortData());
    } catch (e) {
      this.setState({
        error: e
      });
    } finally {
      this.setState({
        isLoading: false
      });
    }
  }
  public componentDidUpdate(prevProps: Props) {
    if (prevProps.filters.term !== this.props.filters.term || prevProps.filters.selectedProjects !== this.props.filters.selectedProjects || prevProps.filters.startDate !== this.props.filters.startDate || prevProps.filters.endDate !== this.props.filters.endDate || prevProps.sortSettings.direction !== this.props.sortSettings.direction || prevProps.sortSettings.column !== this.props.sortSettings.column) {
      this.filterAndSortData();
    }
  }
  private filterAndSortData() {
    const {
      scheduledChecklists
    } = this.state;
    const {
      filters,
      sortSettings
    } = this.props;
    let data = [...scheduledChecklists];
    if (filters.term) {
      data = data.filter(x => x.title.toLocaleLowerCase().includes(filters.term) || x.project_name && x.project_name.toLocaleLowerCase().includes(filters.term));
    }
    if (filters.selectedProjects.length) {
      const projectIds = filters.selectedProjects.map(x => x.value);
      data = data.filter(x => projectIds.includes(x.project_id));
    }
    if (filters.startDate) {
      data = data.filter(x => x.start_date >= filters.startDate);
    }
    if (filters.endDate) {
      data = data.filter(x => x.start_date <= filters.endDate);
    }
    if (sortSettings.column === 'title') {
      data = data.sort((a, b) => a.title.localeCompare(b.title));
    } else if (sortSettings.column === 'start_date') {
      // @ts-ignore
      data = data.sort((a, b) => a.start_date - b.start_date);
    } else if (sortSettings.column === 'due_date') {
      // @ts-ignore
      data = data.sort((a, b) => a.due_date - b.due_date);
    } else if (sortSettings.column === 'project') {
      data = data.sort((a, b) => a.project_name ? a.project_name.localeCompare(b.project_name) : 0);
    }
    if (sortSettings.direction === 'desc') {
      data = data.reverse();
    }
    this.setState({
      processedData: data
    });
  }
  private handlePagination = (page: number) => {
    this.setState({
      currentPage: page
    });
  };
  private handleSort = (field: string, direction: 'asc' | 'desc') => {
    this.props.setSort({
      column: field,
      direction
    });
  };
  public render() {
    const {
      sortSettings,
      t
    } = this.props;
    const spinner = this.state.isLoading ? <LoadSpinner className="centered" /> : null;
    const begin = (this.state.currentPage - 1) * PER_PAGE;
    const scheduledChecklistsData = this.state.processedData.slice(begin, PER_PAGE + begin).map(x => ({
      title: <div className={x.autostart && 'has-not-allowed-cursor'}>
            <Link className={clsx('has-hyphens', 'is-inline-flex-mobile', x.non_working_day_excluded && 'has-text-strike-through', (x.autostart || x.non_working_day_excluded) && 'is-disabled')} to={`/resultset/${x.checklist_id}/new?due_date=${encodeURI((x.due_date as any))}` + `&project_id=${x.project_id}&tester_id=${x.user_ids.join(',')}&title=${encodeURI(x.title)}&team_ids=${x.team_ids.join(',')}&location=${x.location}&contact_id=${x.contact_id}&resource_id=${x.ressource_id}` + `&schedule_id=${x.schedule_id}`}>
              {x.title}
            </Link>
            {x.non_working_day_excluded && <Tooltip title={t('schedule.nonWorkingDayTooltip')}>
                  <InfoIcon sx={{
            height: '0.75em'
          }} />
                </Tooltip>}
            {x.autostart && <Tag className="has-margin-left-0 is-autostart-tag" isColor="yellow">
              {t('overview.startsAutomatically')}
            </Tag>}
          </div>,
      due_date: x.due_date,
      start_date: x.start_date,
      rawTitle: x.title,
      id: `${x.schedule_id}-${x.due_date}`,
      project: <div className="is-ellipsed action-table-project">{x.project_name ? x.project_name : ''}</div>,
      rawProject: x.project_name ? x.project_name : '',
      project_id: x.project_id
    }));
    const headers: IDataTableHeader[] = [{
      field: 'title',
      label: t('resultSet.title'),
      width: '30%'
    }, {
      field: 'start_date',
      label: t('schedule.fields.startDate'),
      formatAs: 'dueDate',
      width: isMobile ? '30%' : '20%'
    }, {
      field: 'due_date',
      label: t('resultSet.dueShort'),
      formatAs: 'dueDate',
      width: isMobile ? '30%' : '20%'
    }];
    if (!isMobile) {
      headers.push({
        field: 'project',
        label: 'Projekt',
        width: '20%'
      });
    }
    return <>
        {spinner}
        <DataTable isLoading={this.state.isLoading} data={scheduledChecklistsData} actions={[]} headers={headers} currentPage={this.state.currentPage} onPagination={this.handlePagination} onSort={this.handleSort} sortColumn={sortSettings.column} sortDir={sortSettings.direction} totalPages={Math.floor(this.state.processedData.length / PER_PAGE)} />
      </>;
  }
}
const mapState = (state: IAlphaprocessState): IStateProps => ({
  sortSettings: state.sort.nextInspectionsSort,
  isAdmin: state.core.user?.current_role === 'admin',
  filters: state.filter.checklistFilterSettings
});
const mapDispatch = (dispatch: (x: any) => void): IDispatchProps => ({
  setSort: settings => dispatch(setNextInspectionsSort(settings))
});
export default connect(mapState, mapDispatch)(withTranslation()(ScheduledChecklistsTable));